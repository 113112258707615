<template>
    <div v-if="isLoading" class="min-h-screen w-full flex items-center justify-center bg-black">
        <div role="status">
            <svg aria-hidden="true"
                class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else-if="!isLoading && (!eventTemplateData)"
        class="min-h-screen w-full flex items-center justify-center bg-black">
        <h1 class="font-medium text-primary-600 text-gray-400">
            We couldn't find an invitation link<br><a
                class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                href="https://api.whatsapp.com/send?phone=31638882552">Please reach out if this is unexpected</a>
        </h1>
    </div>
    <div v-else class="w-screen h-full flex flex-col justify-center items-center text-white"
        :style="{ backgroundColor: backgroundColor }">
        <div v-if="video_src" id="video-background-wrapper" class="video-background-wrapper">
            <div class="fullscreen-video-wrapper">
                <div class="overlay-loader"></div>
                <div class="vimeo-wrapper">
                    <video :src="video_src" autoplay loop muted playsinline>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
        <div v-if="event.status !== 'active' || version.status === 'CLOSED'"
                class="z-50 mt-20 w-full h-full flex flex-col justify-center items-center bg-gray-800 max-w-lg md:max-w-xl rounded p-10">
                <h5 v-if="eventTemplateData" class="text-center mb-2 text-2xl font-bold tracking-tight"
                    :style="{ color: eventTemplateData.locked_text_color }">
                    {{ eventTemplateData.locked_text_subject }}
                </h5>

                <hr class=" h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
                <a href="https://api.whatsapp.com/send?phone=31638882552"
                    class="flex justify-center items-center hover:underline hover:text-white">
                    <div v-if="eventTemplateData" :style="{ color: eventTemplateData['locked_link_color'] }">
                        <div v-html="eventTemplateData['locked_text_body']"></div>
                    </div>
                </a>
            </div>
        <div class="z-50 flex flex-col items-center justify-center px-6 py-8 mx-auto md:min-h-screen pt:mt-0">
            <a v-bind:href="'signed24'" class="flex items-center mb-6 text-2xl font-semibold text-white">
                <img class="h-12 mr-2" :src="eventTemplateData['event_logo']" alt="Logo">
            </a>
            <div>
                <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                        <h2
                            class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
                            {{ versionTemplateData.subject }}
                        </h2>
                        <p
                            class="text-sm font-medium leading-tight tracking-tight text-center text-gray-700 md:text-md dark:text-gray-300">
                            {{ versionTemplateData.signup_subject_sub_text }}
                        </p>
                        <div v-if="formResponse">
                            <div class="">
                                <FwbAlert type="info" title="" class="mb-2">
                                    <p class="text-center border border-blue-500 rounded-lg py-2.5 px-4">
                                        {{ eventTemplateData.signup_confirmation_text }}</p>
                                </FwbAlert>
                            </div>
                        </div>
                        <form v-else class="space-y-4 md:space-y-6" @submit.prevent="formRequest">
                            <div class="grid gap-6 mb-6 md:grid-cols-2">
                                <div>
                                    <FormInput id="first_name" :label="versionTemplateData.first_name"
                                        v-model="formState.first_name" :error="validationErrors.first_name"
                                        placeholder="John" />
                                </div>
                                <div>
                                    <FormInput id="last_name" :label="versionTemplateData.last_name"
                                        v-model="formState.last_name" :error="validationErrors.last_name"
                                        placeholder="Doe" />
                                </div>
                            </div>

                                <div>
                                    <FormInput id="phone" :label="versionTemplateData.phone"
                                        v-model="formState.phone" :error="validationErrors.phone"
                                        :explanation_first="versionTemplateData.phone_text_one"
                                        :explanation_second="versionTemplateData.phone_text_two"
                                        placeholder="+31688888888" type="phone" />
                                </div>
                                <div>
                                    <FormInput id="email" label="E-mail" v-model="formState.email"
                                        :error="validationErrors.email"
                                        :explanation_first="versionTemplateData.email"
                                        placeholder="name@company.com" type="text" />
                                </div>
                                <div>
                                    <FormTicketInput id="tickets" :label="versionTemplateData.tickets"
                                        v-model="formState.number_of_tickets"
                                        :max_tickets="ft_atoi(versionTemplateData.sign_up_max_tickets)" />
                                </div>
                                <div v-if="versionTemplateData['parking_tickets']">
                                    <FormTicketInput id="parking_tickets"
                                        :label="versionTemplateData.parking_tickets"
                                        v-model="formState.number_of_parking_tickets"
                                        :max_tickets="ft_atoi(versionTemplateData.sign_up_max_parking_tickets)" />
                                </div>
                                <div v-if="versionTemplateData.comments">
                                    <FormInputText
                                        :label="versionTemplateData.comments"
                                        :error="validationErrors.comments"
                                        v-model="formState.comments"
                                        explanation_first="Vermeld hier de naam van het bedrijf of de organisatie waarmee je verbonden bent."
                                    />
                                </div>
                            <div class="flex items-start">
                                <div class="flex items-center mr-4">
                                    <input v-model="isCheckboxChecked" id="terms" aria-describedby="terms"
                                        type="checkbox"
                                        class="accent-purple-600 w-4 h-4 text-purple-500 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        required="">
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="terms" class="font-light text-gray-500 dark:text-gray-300">
                                        {{ eventTemplateData.legal_text_one }}<a
                                            class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                            :href="eventTemplateData.legal_link">{{ eventTemplateData.legal_text_two }}</a>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <button class="w-full" type="submit" :disabled="!isCheckboxChecked" :class="[
                                    'text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center',
                                    !isCheckboxChecked ? 'opacity-50 cursor-not-allowed' : ''
                                ]" :style="{
                                    background: `linear-gradient(to right, ${versionTemplateData.aanmelden_from_color}, ${versionTemplateData.aanmelden_to_color})`,
                                    color: 'white',
                                    padding: '0.5rem 1rem',
                                    borderRadius: '0.25rem'
                                }">{{ versionTemplateData.submit_text }}</button>
                            </div>
                        </form>
                        <div class="flex !mt-6">
                            <a type="button" href="https://livecrowd.com/support/" target="_blank"
                                class="w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded text-sm py-2.5 text-center">Neem
                                contact op met Livecrowd</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import FormInput from './FormInput.vue';
import FormTicketInput from './FormTicketInput.vue';
import FormInputText from './FormInputText.vue';
import { ref, onMounted } from 'vue'
import { initFlowbite } from 'flowbite'
import { FwbAlert } from 'flowbite-vue'
import { useHead } from '@vueuse/head'
import { useToNumber } from '@vueuse/core'
import { z } from 'zod';
import { useRoute } from 'vue-router'

const route = useRoute()

const isCheckboxChecked = ref(false);
const formResponse = ref("");
const baseUrl = ref(import.meta.env.VITE_BASE_URL);
const isLoading = ref(true);
const backgroundColor = ref("#000000");

const video_src = ref("")
const favicon = ref("")
const title = ref("Crafture")
const eventTemplateData = ref('');
const versionTemplateData = ref('');
const event = ref(null)
const version = ref(null)

useHead({
    title: title,
    link: [
        {
            rel: 'icon',
            type: 'image/png',
            href: favicon
        }
    ]
})

onMounted(() => {
    initFlowbite();
    fetchEventVersion();
})

const formState = ref({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    number_of_tickets: 1,
    number_of_parking_tickets: 0,
    comments: "",
    version: 0,
});

const validationErrors = ref({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    number_of_tickets: '',
    number_of_parking_tickets: '',
    version: '',
    comments: ''
});

const formSchema = z.object({
    first_name: z.string().min(2, { message: 'Voornaam is verplicht en 2 tekens lang minimaal' }),
    last_name: z.string().min(2, { message: 'Achternaam is verplicht en 2 tekens lang minimaal' }),
    email: z.string().email({ message: 'Verkeerd e-mailadres' }),
    phone: z.string().optional().refine(
        value => !value || /^\+\d{10,15}$/.test(value),
        { message: 'Telefoonnummer moet een geldig internationaal formaat hebben, zoals +31688888888' }
    ),
    number_of_tickets: z.number().min(1, { message: 'Aantal tickets moet minstens 1 zijn' }),
    comments: z.string().max(512, { message: 'Opmerkingen mogen maximaal 512 tekens bevatten' }).optional(),
});


const fetchEventVersion = async () => {
    try {
        const response = await fetch(`${baseUrl.value}/api/event/${route.params.event_slug}/${route.params.version_slug}/`);
        const data = await response.json();

        eventTemplateData.value = data.event.json_template
        if (eventTemplateData.value.bg_color) {
            backgroundColor.value = eventTemplateData.value.bg_color
        }
        title.value = data.event.display_name
        favicon.value = baseUrl.value + data.event.favicon
        video_src.value = data.event.json_template.video_src
        versionTemplateData.value = data.version.json_template

        event.value = data.event;
        version.value = data.version;
        formState.value.version = version.id;

    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

const ft_atoi = (str) => {
    const number = useToNumber(str)
    return number.value
}

// Function to clear validation errors
const clearValidationErrors = () => {
    validationErrors.value = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        number_of_tickets: '',
        number_of_parking_tickets: ''
    };
};

const formRequest = async () => {
    try {
        console.log("Alpha")
        clearValidationErrors();
        const result = formSchema.safeParse({
            ...formState.value,
        });

        console.log("Beta")
        if (!result.success) {
            result.error.issues.forEach(issue => {
                validationErrors.value[issue.path[0]] = issue.message;
            });
            return;
        }
        console.log("Charlie")

        const formData = new FormData();
        formData.append('first_name', formState.value.first_name);
        formData.append('last_name', formState.value.last_name);
        formData.append('email', formState.value.email);
        formData.append('phone', formState.value.phone);
        formData.append('number_of_tickets', formState.value.number_of_tickets);
        formData.append('number_of_parking_tickets', formState.value.number_of_parking_tickets);
        formData.append('comments', formState.value.comments);
        formData.append('version', ft_atoi(version.value.id));

        console.log("Delta")
        const response = await fetch(
            `${baseUrl.value}/api/applicant/create/`,
            {
                method: 'POST',
                body: formData,
                mode: 'cors',
            },
        );
        const data = await response.json();
        formResponse.value = data;
    } catch (error) {
        console.error("Form submission failed:", error);
        return error;
    }
};

</script>
