<template>
    <div :class="['mb-4', errorMessage ? 'pl-2 border-red-500 border-l' : '']">
      <label :for="id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {{ label }}
      </label>
      <p v-if="explanation_first" id="helper-text-explanation" class="mb-2 text-sm text-gray-500 dark:text-gray-400">
      {{ explanation_first }}
        </p>
      <textarea
        :id="id"
        :name="id"
        :class="[
          'bg-gray-50 min-h-[150px] text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white',
          errorMessage ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
        ]"
        v-model="model"
        :placeholder="placeholder"
        :value="modelValue"
      ></textarea>
      <p v-if="errorMessage" class="text-red-500 text-sm">{{ errorMessage }}</p>
    </div>
  </template>

  <script setup>
    import { ref, watch, defineProps } from 'vue';

    const props = defineProps({
        id: {
            type: String,
            default: 'comments'
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: 'Typ hier...'
        },
        errorMessage: {
            type: String,
            default: ''
        },
        explanation_first: {
          type: String,
          default: '',
        },
        explanation_second: {
          type: String,
          default: '',
        },
    });

    const model = defineModel();

    const inputField = ref(null);
    const inputWrapper = ref(null);

    watch(() => props.error, (newError) => {
      if (newError) {
        inputWrapper.value?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        inputField.value?.focus();
      }
    });

  </script>
