<template>
    <div :class="[ 'mb-4', error ? 'pl-2 border-red-500 border-l' : '' ]"
        ref="inputWrapper">
      <!-- Label -->
      <label v-if="label" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {{ label }}
      </label>

      <!-- Select -->
      <select
        class="block w-full p-2.5 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
         v-model="model"
        @change="onChange"
      >
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.key"
          :disabled="option.disabled"
        >
          {{ option.value }}
        </option>
      </select>
      <p v-if="error" class="text-red-500 text-sm">{{ error }}</p>
    </div>
  </template>

  <script setup>
  import { ref, watch } from 'vue'
  

  // Props
  const props = defineProps({
    // Text label for this dropdown
    label: {
      type: String,
      default: '',
    },
    // Array of objects, each { key: any, value: string }
    options: {
      type: Array,
      default: () => [],
    },
    error: String,
  })

  const model = defineModel();
  const inputField = ref(null);
  const inputWrapper = ref(null);


  watch(() => props.error, (newError) => {
    if (newError) {
      inputWrapper.value?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      inputField.value?.focus();
    }
  });
  </script>
