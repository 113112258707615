import { createWebHistory, createRouter } from "vue-router";
import RegisterForm from '../components/RegisterForm.vue'
import DeclinePage from '../components/DeclinePage.vue'
import ExtraInformation from "../components/ExtraInformation.vue";
import NotFound from '../views/NotFound.vue'
import FamilyPage from "../components/FamilyPage.vue";
import Maintenance from "../components/Maintenance.vue";

import SignedRegister from "../components/SignedRegister.vue";
import WorkshopSessions from "../components/WorkshopSessions.vue";
import SignUpAmpco from "../components/SignUpAmpco.vue"
import SignUpDynamic from "../components/SignUpDynamic.vue";

const routes = [
    {
        path: "/",
        name: "Registreren",
        component: RegisterForm,
    },
    {
        path: "/afmelden",
        name: "Afmelden",
        component: DeclinePage,
    },
    {
        path: "/extra-information",
        name: "Extra Information",
        component: ExtraInformation
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/family',
        name: 'Family',
        component: FamilyPage

    },
    {
        path: "/signup",
        name: "AanmeldenAmpco",
        component: SignUpAmpco
    },
    {
        path: "/register",
        name: "RegistrerenSigned",
        component: SignedRegister
    },
    {
        path: "/sessions",
        name: "Worksshop Sessions",
        component: WorkshopSessions
    },
    {
        path: "/signup/:event_slug/:version_slug",
        name: "SignUp Dynamic",
        component: SignUpDynamic
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


const allowedDomains = [
    /^localhost(:\d+)?$/,
    /^deploy-preview-\d+--.*\.netlify\.app$/,
    /^24\.signed\.crafture\.com$/
];

const allowedDomainsAmpco = [
    /^localhost(:\d+)?$/,
    /^deploy-preview-\d+--.*\.netlify\.app$/,
    /^register\.ampco\.app$/
];


router.beforeEach((to, from, next) => {
    if (to.name === 'AanmeldenSigned' || to.name === 'RegistrerenSigned' || to.name === 'Extra information Signed') {
        const currentDomain = window.location.hostname
        const isAllowed = allowedDomains.some(pattern => pattern.test(currentDomain))

        if (!isAllowed) {
            // Redirect to 404 page if not an allowed domain
            return next({ name: 'NotFound' })
        }
    }
    if (to.name === 'AanmeldenAmpco') {
        const currentDomain = window.location.hostname
        const isAllowed = allowedDomainsAmpco.some(pattern => pattern.test(currentDomain))

        if (!isAllowed) {
            // Redirect to 404 page if not an allowed domain
            return next({ name: 'NotFound' })
        }
    }
    next()
})
export default router;
