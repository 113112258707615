<template>
    <div :class="[ 'mb-4',  error ? 'pl-2 border-red-500 border-l' : '']">
        <label for="comments"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{label}}</label>
        <p v-if="explanation_first" id="helper-text-explanation" class="italic mb-2 text-sm text-gray-500 dark:text-gray-400">
            {{ explanation_first }}
        </p>
        <textarea type="comments" name="comments" id="comments" ref="textField"
            :class="[
                'bg-gray-50 min-h-[150px] text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white',
                 error ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
            ]"
            :placeholder="placeholder" v-model="model" />
        <p v-if=" error" class="text-red-500 text-sm">{{  error }}</p>
    </div>
    <p v-if="explanation_second" id="helper-text-explanation" class="mb-2 text-sm text-gray-500 dark:text-gray-400">
      {{ explanation_second }}
    </p>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  label: String,
  placeholder: {
    type: String,
    default: '',
  },
  error: String,
  explanation_first: {
    type: String,
    default: '',
  },
  explanation_second: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Typ hier...'
  }
});

const model = defineModel();
const textField = ref(null);
const inputWrapper = ref(null);

watch(() => props.error, (newError) => {
  if (newError) {
    inputWrapper.value?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    textField.value?.focus();
  }
});
</script>

