<template>
    <div class="w-screen h-full flex flex-col justify-center items-center text-white py-6 md:py-0">
        <div v-if="videoUrl" id="video-background-wrapper" class="video-background-wrapper">
            <div class="fullscreen-video-wrapper">
                <div class="overlay-loader"></div>
                <div class="vimeo-wrapper">
                    <video :src="videoUrl" autoplay loop muted playsinline>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
        <section v-if="invitee && invitee.event.status !== 'active'">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
                <div class="z-10 w-full bg-white text-black rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                        <a v-if="eventTemplateData" :href="'mailto:' + eventTemplateData['locked_text_link']"
                            class="font-semibold leading-normal text-gray-700 dark:text-white">
                            {{ eventTemplateData['locked_text_subject'] }}
                        </a>

                        <hr class=" h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
                        <a href="https://api.whatsapp.com/send?phone=31638882552"
                            class="flex justify-center items-center hover:underline hover:text-white">
                            <div v-if="eventTemplateData" :style="{ color: eventTemplateData['locked_link_color'] }">
                                <div v-html="eventTemplateData['locked_text_body']"></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section v-else-if="invitee"
            class="flex flex-col justify-center items-center w-full h-full overflow-auto  md:min-h-screen">
            <a v-bind:href="'?ref=' + refParam" class="z-50 flex items-center mt-4 mb-6 text-2xl font-semibold text-white">
                <img class="w-48 mr-2" :src="eventTemplateData['event_logo']" alt="Logo">
            </a>
            <div class="z-10 w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 p-4">
                <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                    <h2
                        class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        {{ selectedVersion.json_template['form-title'] }}
                    </h2>
                    <p class="text-black dark:text-white text-sm" style="margin-top: 0;">
                        {{ selectedVersion.json_template['help_text'] }}
                    </p>
                    <div v-if="formResponse.status">
                        <div class="flex flex-col">
                            <FwbAlert type="info" title="" class="mb-2"><p class="text-center border border-blue-500 rounded-lg py-2.5 px-4">{{ formResponse.message }}</p></FwbAlert>
                            <div class="flex flex-col">
                            <p class="text-xs text-white">
                                {{ selectedVersion.json_template['neem_contact_op_help_text'] }}
                            </p>
                            <a type="button" href="https://livecrowd.com/support/" target="_blank"
                                class="w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded text-sm py-2.5 text-center">{{ selectedVersion.json_template['neem_contact_op'] }}</a>
                        </div>
                        </div>
                    </div>
                    <form v-else class="space-y-4 md:space-y-6" @submit.prevent="formRequest">
                        <div>
                            <div id="tooltip_email" role="tooltip"
                                class="absolute text-xs z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                {{ selectedVersion.json_template['tooltip'] }}
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </div>

                        <div class="w-full flex gap-4">
                            <div>
                                <div id="tooltip_firstname" role="tooltip"
                                    class="absolute text-xs z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    {{ selectedVersion.json_template['tooltip'] }}
                                    <div class="tooltip-arrow" data-popper-arrow></div>
                                </div>
                                <label for="first_name"
                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{
                                        selectedVersion.json_template['first_name'] }}</label>
                                <input type="text" id="first_name" disabled data-tooltip-target="tooltip_firstname"
                                    class="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="John" v-model="invitee.first_name" required>
                            </div>
                            <div>
                                <div id="tooltip_lastname" role="tooltip"
                                    class="absolute text-xs z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    {{ selectedVersion.json_template['tooltip'] }}
                                    <div class="tooltip-arrow" data-popper-arrow></div>
                                </div>
                                <label for="last_name"
                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{
                                        selectedVersion.json_template['last_name'] }}</label>
                                <input type="integer" id="last_name" disabled data-tooltip-target="tooltip_lastname"
                                    class="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Doe" v-model="invitee.last_name" required>
                            </div>
                        </div>
                        <div
                            v-for="(clusterItem, index) in clusters"
                            :key="clusterItem.id"
                            class="mb-4"
                            >
                            <label :for="`cluster-${clusterItem.id}`" class="font-semibold block mb-1 text-gray-700 dark:text-gray-200">
                                {{ clusterItem.name }}
                            </label>
                            <div class="flex space-x-3">
                                <select
                                    :id="`cluster-${clusterItem.id}`"
                                    v-model="selectedSessions[clusterItem.id]"
                                    class="border border-gray-300 dark:border-gray-500 rounded p-2 dark:bg-gray-700 w-full text-gray-700 dark:text-gray-200"
                                >
                                    <option value="" selected disabled>-- Kies een sessie --</option>
                                    <option
                                        v-for="session in clusterItem.sessions"
                                        :key="session.id"
                                        :value="session.id"
                                    >
                                    {{ session.name }}
                                    </option>
                                </select>
                                <div
                                class="border px-2 rounded-sm border-gray-300 dark:border-gray-500 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-900 cursor-pointer"
                                @click="removeSelectedSession(clusterItem.id)"
                                >
                                <p class="text-3xl dark:text-gray-200 text-gray-600">x</p>
                            </div>
                            </div>
                        </div>
                        <div v-if="selectedVersion.json_template['comments']">
                            <FormInputText
                                :label="selectedVersion.json_template['comments']"
                                :placeholder="selectedVersion.json_template['comments_default_text']"
                                :errorMessage="validationErrors.comments"
                                v-model="formState.comments"
                            />
                        </div>
                        <div class="flex items-center mr-4">
                            <input v-model="isCheckboxChecked" id="terms" aria-describedby="terms" type="checkbox"
                                class="accent-purple-600 w-4 h-4 text-purple-500 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                >
                            <label for="terms" class="ml-3 text-sm font-light text-gray-500 dark:text-gray-300">{{
                                eventTemplateData['legal_text_one'] }}<a
                                    class="font-medium text-primary-600 hover:underline dark:text-primary-500 text-sm"
                                    :href="eventTemplateData['legal_link']">{{ eventTemplateData['legal_text_two'] }}</a>
                            </label>
                        </div>
                        <div v-if="Array.isArray(formResponse.sessions_added) && formResponse.sessions_added.length > 0" class="border border-blue-500 p-4 text-blue-500">
                            <p class="mb-2">Sessies toegevoegd</p>
                            <ul class="list-disc text-blue-500 pl-2">
                                <li v-for="msg in formResponse.sessions_added" :key="msg.name" class="mb-2">
                                    <p class="text-sm text-left">{{ msg.name }} - {{ formatDate(msg.start_datetime) }}</p>
                                </li>
                            </ul>
                        </div>
                        <div v-if="Array.isArray(formResponse.sessions_removed) && formResponse.sessions_removed.length > 0" class="border border-blue-500 p-4 text-blue-500">
                            <p class="mb-2">Sessies geanuleerd</p>
                            <ul class="list-disc pl-2">
                                <li v-for="msg in formResponse.sessions_removed" :key="msg.name" class="mb-2">
                                    <p class="text-sm text-left">{{ msg.name }} - {{ formatDate(msg.start_datetime) }}</p>
                                </li>
                            </ul>
                        </div>
                        <div v-else-if="formErrors">
                            <div v-for="error in formErrors">
                                <p class="text-red-500 ring-2 ring-red-500 rounded-sm px-2">{{ error }}</p>
                            </div>
                        </div>
                        <div>
                            <button class="w-full" type="submit" :disabled="!isCheckboxChecked" :class="[
                                'text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center',
                                !isCheckboxChecked ? 'opacity-50 cursor-not-allowed' : ''
                            ]" :style="{
                                background: `linear-gradient(to right, ${selectedVersion.json_template['aanmelden_from_color']}, ${selectedVersion.json_template['aanmelden_to_color']})`,
                                color: 'white',
                                borderRadius: '0.25rem'
                            }">{{ selectedVersion.json_template['submit_text'] }}</button>
                        </div>
                        <div class="flex flex-col mt-6">
                            <p class="text-xs text-white mb-2">
                                {{ selectedVersion.json_template['neem_contact_op_help_text'] }}
                            </p>
                            <a type="button" href="https://livecrowd.com/support/" target="_blank"
                                class="w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded text-sm py-2.5 text-center">{{ selectedVersion.json_template['neem_contact_op'] }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <section v-else>
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
                <div class="z-10 w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8 text-white">
                        <h1 class="font-medium text-primary-600 text-gray-400">
                            We couldn't find an invitation link<br><a
                                class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                href="https://api.whatsapp.com/send?phone=31638882552">Please reach out if this
                                is
                                unexpected</a>
                        </h1>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { initFlowbite } from 'flowbite'
import { useHead } from '@vueuse/head'
import { FwbAlert } from 'flowbite-vue'
import FormInputText from './FormInputText.vue';
import { z } from 'zod';

const refParam = ref('');
const invitee = ref(null);
const clusters = ref([])
const eventTemplateData = ref('');
const isLoading = ref(true);
const baseUrl = ref(import.meta.env.VITE_BASE_URL);
const videoUrl = ref('');
const selectedVersion = ref("");
const isCheckboxChecked = ref(false);
const formResponse = ref("");
const formErrors = ref("");
const showAlertTimeout = ref("");

const title = ref("Crafture")
const favicon = ref("")

const queryParams = new URLSearchParams(window.location.search)
const refValue = queryParams.get('ref')
refParam.value = refValue;

const originalSelectedSessions = ref({})
const selectedSessions = ref({});
const sessionsToRemove = ref([]);

const formState = ref({
    comments: "",
});

const validationErrors = ref({
    comments: '',
});

const formSchema = z.object({
  comments: z.string().max(512, { message: 'Opmerkingen mogen maximaal 512 tekens bevatten' })
});

const clearValidationErrors = () => {
  validationErrors.value = {
    comments: '',
  };
};

useHead({
    title: title,
    link: [
        {
            rel: 'icon',
            type: 'image/png',
            href: favicon
        }
    ]
})

onMounted(() => {
    initFlowbite();
    fetchAttendee();

})


const formatDate = (isoString) => {
  const date = new Date(isoString);
  const formatter = new Intl.DateTimeFormat("nl-NL", {
    hour: "numeric",
    minute: "numeric",
    month: "numeric",
    day: "numeric",
    year: "2-digit",
  });
  return formatter.format(date);
};

const fetchAttendee = async () => {
    try {
        const response = await fetch(`${baseUrl.value}/api/attendee/?ref=${refParam.value}`);
        if (response.status != 200)
            return
        const data = await response.json();
        invitee.value = data;
        eventTemplateData.value = data.event.json_template
        title.value = data.event.display_name
        favicon.value = baseUrl.value + data.event.favicon
        videoUrl.value = data.event.json_template.video_src

        let versionsArray = Array.isArray(invitee.value.versions) ? invitee.value.versions : [invitee.value.versions];

        // Now you can safely access the first element if it exists
        if (versionsArray.length === 1) {
            selectedVersion.value = versionsArray[0];
        }
        fetchWorkshops();

    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

const fetchWorkshops = async () => {
  try {
    const response = await fetch(`${baseUrl.value}/api/clusters-for-event/${selectedVersion.value.slug}/`);
    if (response.status !== 200) return;
    const data = await response.json();

    clusters.value = data;

    // For each cluster, see if the attendee has a session in that cluster:
    clusters.value.forEach(cluster => {
      // Try to find a match between the cluster's sessions and the attendee's sessions
      const matchedSession = cluster.sessions.find(session => {
        return invitee.value.sessions?.some(invSession => invSession.id === session.id)
      })
      selectedSessions.value[cluster.id] = matchedSession ? matchedSession.id : ""
    })
    originalSelectedSessions.value = { ...selectedSessions.value };
  } catch (error) {
    console.error(error);
  }
};

const formRequest = async () => {
  // 1. Convert `originalSelectedSessions` and `selectedSessions` to arrays
  const oldSessionIds = Object.values(originalSelectedSessions.value);
  const newSessionIds = Object.values(selectedSessions.value);

  // 2. Identify which sessions to remove (in old but not in new)
  const sessionsToRemove = oldSessionIds.filter(sid => !newSessionIds.includes(sid) && sid !== "");
  // 3. Identify which sessions to add (in new but not in old)
  const sessionsToAdd = newSessionIds.filter(sid => !oldSessionIds.includes(sid) && sid !== "");

  try {
    let removeData = null;
    let addData = null;

    // 4a. If there are sessions to remove
    if (sessionsToRemove.length > 0) {
      const removeRes = await fetch(`${baseUrl.value}/api/sessions/remove-attendee-multiple/${invitee.value.uuid}/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ session_ids: sessionsToRemove }),
      });
      if (!removeRes.ok) {
        const errorData = await removeRes.json();
        formErrors.value = errorData;
        return;
      }
      removeData = await removeRes.json();
    }

    // 4b. If there are sessions to add
    if (sessionsToAdd.length > 0) {
      const addRes = await fetch(`${baseUrl.value}/api/sessions/add-attendee-multiple/${invitee.value.uuid}/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ session_ids: sessionsToAdd }),
      });
      if (!addRes.ok) {
        const errorData = await addRes.json();
        formErrors.value = errorData;
        return;
      }
      addData = await addRes.json();
    }
    formResponse.value = {
        sessions_removed: removeData?.sessions_removed || [],
        sessions_added: addData?.sessions_added || []
    };

    originalSelectedSessions.value = { ...selectedSessions.value };
    commentsRequest();

    // Show success message
  } catch (error) {
    console.error("Error in formRequest:", error);
  }
};

const commentsRequest = async () => {
    try {
        clearValidationErrors();
        const result = formSchema.safeParse({
            ...formState.value,
        });

        if (!result.success) {
            result.error.issues.forEach(issue => {
                validationErrors.value[issue.path[0]] = issue.message;
            });
            return;
        }
        const formData = new FormData();
        formData.append('comments', formState.value.comments);

        const response = await fetch(
            `${baseUrl.value}/api/update_attendee/${invitee.value.uuid}/`,
            {
                method: 'PATCH',
                body: formData,
                mode: 'cors',
            },
        );
        const data = await response.json();
    } catch (error) {
        console.error("Form submission failed:", error);
        return error;
    }
}

const removeSelectedSession = (clusterId) => {
  const sessionId = selectedSessions.value[clusterId];
  if (!sessionId) return; // No session selected, do nothing

  // 3. Reset dropdown to no selection
  selectedSessions.value[clusterId] = "";
};

</script>
